import { ReactNode } from 'react';

export enum FETCH_METHODS {
  GET = 'GET',
  PATCH = 'PATCH',
  POST = 'POST',
  PUT = 'PUT',
  DELETE = 'DELETE',
}

export type ProviderProps = {
  children: ReactNode;
};

export type PlayerType = {
  id: number;
  user_id: number;
  story_id: number;
  quest_id: number;
  quest_active: boolean;
  name: string;
  state: string;
};

export type UserData = {
  username: string;
  turns_left: number;
  coins_left: number;
  id: number;
  players: PlayerType[];
  avatar: string;
  quests_completed: number;
  subscription_ends_at: number | null;
  free_turns_at: number;
  subscribed: boolean;
  invite_code: string;
  tasks_completed: number;
  referrer_id: number;
};

export type SubscriptionType = {
  id: string;
  name: string;
  description: string;
  price: number;
  currency: string;
  period: number;
};

export type CompletionRewardType = {
  tasks: number;
  turns?: number;
  coins?: number;
};

export type UserParams = {
  turns_recover_interval: string;
  turns_for_free: string;
  turns_for_subscribed: string;
  referral_reward_coins: string;
  task_completion_rewards: CompletionRewardType[];
};

export type ReferralType = {
  coins: number;
  created_at: number;
  id: number;
  params: null;
  player_id: null;
  referral: {
    avatar: string;
    username: string;
  };
  status: string;
  status_name: string;
  turns: number;
  type: string;
  type_name: string;
};

export type RewardType = {
  coins: number;
  created_at: number;
  id: number;
  params: { task_id: number; user_task_id: number; completed_count: number };
  player_id: null;
  status: string;
  status_name: string;
  turns: number;
  type: string;
  type_name: string;
};

export type TaskType = {
  description: string;
  icon: string;
  id: number;
  reward_coins: number;
  reward_turns: number;
  title: string;
  link: string;
  user_task: {
    id: number;
    created_at: number;
    completed_at: number | null;
    reward: RewardType | null;
  };
  available: boolean;
  available_at: number;
};

export type QuestType = {
  id: number;
  pos: number;
  title: string;
  coins: number;
  banner: string;
  intro: string;
  slides: {
    bg: string;
    text: string;
    title: string;
  }[];
};

export type NavTabType = {
  label: string;
  icon: string;
  activeIcon: string;
  url: string;
};

export type PlayerQuestType = {
  id: number;
  created_at: number;
  quest: QuestType;
  completed_at: number;
  turns_done: number;
};

export type StoryType = {
  id: number;
  title: string;
  intro: string;
  banner: string;
  book_author: string;
  book_name: string;
  book_year: number;
  source_link: string;
  tags: string[];
  welcome_image: string;
  welcome_title: string;
  welcome_text: string;
  player: PlayerType;
  quest_count: number;
  first_quest: QuestType;
  player_quest: PlayerQuestType;
  player_quest_id: number;
};

export type TourState = {
  active: boolean;
  repeat: boolean;
};

export type ProgressDataType = {
  clues: {
    img: string;
    items: string[];
  };
  locations: {
    img: string;
    items: string[];
  };
  characters: {
    img: string;
    items: string[];
  };
};

export type TurnType = {
  prompt: string;
  response: {
    completed: 0;
    message: string;
  };
  timestamp: number;
};
