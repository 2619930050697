import React, { MouseEvent } from 'react';
import cn from 'classnames';
import styles from './ButtonGradient.module.scss';

type ButtonGradientProps = {
  className?: string;
  children: React.ReactNode;
  onClick: () => void;
  variant?: 'purple' | 'blackGold' | 'blackGray' | 'orange' | 'yellow' | 'blue';
  containerClassName?: string;
  disabled?: boolean;
  borderAngle?: number;
};

export const ButtonGradient = ({
  className,
  children,
  onClick,
  variant = 'orange',
  containerClassName,
  disabled,
  borderAngle = 185,
}: ButtonGradientProps) => {
  const wrapperClassName = cn(
    styles.wrapper,
    {
      [styles.disabled]: disabled,
    },
    containerClassName,
  );

  const purpleButtonClassName = cn(
    styles.button,
    styles.buttonPurple,
    className,
  );

  const blueButtonClassName = cn(styles.button, styles.buttonBlue, className);

  const yellowButtonClassName = cn(
    styles.button,
    styles.buttonYellow,
    className,
  );

  const darkButtonClassName = cn(styles.button, styles.buttonDark, className);

  const orangeButtonClassName = cn(
    styles.button,
    styles.buttonOrange,
    className,
  );

  const handleClick = (e: MouseEvent) => {
    e.stopPropagation();
    onClick();
  };

  if (variant === 'purple') {
    return (
      <div
        className={wrapperClassName}
        style={{
          background: `linear-gradient(${borderAngle}deg, #E9DEFE 14.97%, #FFFFFF 49.19%, #B992FD 85.92%) border-box`,
        }}
        onClick={handleClick}
      >
        <button className={purpleButtonClassName}>{children}</button>
      </div>
    );
  }

  if (variant === 'blue') {
    return (
      <div
        className={wrapperClassName}
        style={{
          background: `linear-gradient(${borderAngle}deg, #A8FCFC 14.97%, #FFFFFF 49.19%, #2EDDDE 85.92%) border-box`,
        }}
        onClick={handleClick}
      >
        <button className={blueButtonClassName}>{children}</button>
      </div>
    );
  }

  if (variant === 'yellow') {
    return (
      <div
        className={wrapperClassName}
        style={{
          background: `linear-gradient(${borderAngle}deg, #E5D7BA 14.97%, #FFECCA 49.19%, #E5CC99 85.92%) border-box`,
        }}
        onClick={handleClick}
      >
        <button className={yellowButtonClassName}>{children}</button>
      </div>
    );
  }

  if (variant === 'blackGray') {
    return (
      <div
        className={wrapperClassName}
        style={{
          background: `linear-gradient(${borderAngle}deg, #2D2D2D 13.94%, #716E7A 50.81%, #2D2D2D 96.83%) border-box`,
        }}
        onClick={handleClick}
      >
        <button className={darkButtonClassName}>{children}</button>
      </div>
    );
  }

  return (
    <div
      className={wrapperClassName}
      style={{
        background: `linear-gradient(${borderAngle}deg, #f9d199 18.11%, #ffecca 50.83%,#fe7963 85.96%) border-box`,
      }}
      onClick={handleClick}
    >
      <button className={orangeButtonClassName}>{children}</button>
    </div>
  );
};
