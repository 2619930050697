import { useEffect, useState } from 'react';

export const useTelegramUser = () => {
  const [loaded, setLoaded] = useState(false);
  const [user, setUser] = useState<any>(undefined);

  useEffect(() => {
    setLoaded(true);
  }, []);

  useEffect(() => {
    if (loaded) {
      const telegram = window.Telegram?.WebApp;

      const userData = telegram?.initDataUnsafe.user;

      if (userData) {
        setUser(userData);
      }
    }
  }, [loaded]);

  return user;
};
