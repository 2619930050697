import { createAction } from '@reduxjs/toolkit';

import { ACTIONS } from './constants';
import {
  UserData,
  TourState,
  StoryType,
  SubscriptionType,
  TaskType,
  UserParams,
} from '@/types';

export const setUser = createAction<UserData>(ACTIONS.SET_USER);

export const setOfferActive = createAction<boolean>(ACTIONS.SET_OFFER_ACTIVE);

export const setSubscribeModalActive = createAction<boolean>(
  ACTIONS.SET_SUBSCRIBE_MODAL_ACTIVE,
);

export const setSubscribeSuccessModalActive = createAction<boolean>(
  ACTIONS.SET_SUBSCRIBE_SUCCESS_MODAL_ACTIVE,
);

export const setSubscribeActiveModalActive = createAction<boolean>(
  ACTIONS.SET_SUBSCRIBE_ACTIVE_MODAL_ACTIVE,
);

export const setHowToPlayModalActive = createAction<boolean>(
  ACTIONS.SET_HOW_TO_PLAY_MODAL_ACTIVE,
);

export const setClosedBetaModalActive = createAction<boolean>(
  ACTIONS.SET_CLOSED_BETA_MODAL_ACTIVE,
);

export const setSubscribeUpgradeModalActive = createAction<boolean>(
  ACTIONS.SET_SUBSCRIBE_UPGRADE_MODAL_ACTIVE,
);

export const setKeyboardActive = createAction<boolean>(
  ACTIONS.SET_KEYBOARD_ACTIVE,
);

export const setMysterySolvedModalActive = createAction<boolean>(
  ACTIONS.SET_MYSTERY_SOLVED_MODAL_ACTIVE,
);

export const setOfferTimer = createAction<number>(ACTIONS.SET_OFFER_TIMER);

export const setUserParams = createAction<UserParams>(ACTIONS.SET_USER_PARAMS);

export const setAppLoading = createAction<boolean>(ACTIONS.SET_APP_LOADING);

export const setUserTasks = createAction<TaskType[]>(ACTIONS.SET_USER_TASKS);

export const setAvailableSubscriptions = createAction<SubscriptionType[]>(
  ACTIONS.SET_AVAILABLE_SUBSCRIPTIONS,
);

export const setOfferUpgradeModalActive = createAction<boolean>(
  ACTIONS.SET_OFFER_UPGRADE_MODAL_ACTIVE,
);

export const setOfferDisclaimerModalActive = createAction<boolean>(
  ACTIONS.SET_OFFER_DISCLAIMER_MODAL_ACTIVE,
);

export const setTourState = createAction<TourState>(ACTIONS.SET_TOUR_STATE);

export const setHelpModalActive = createAction<boolean>(
  ACTIONS.SET_HELP_MODAL_ACTIVE,
);

export const setProgressModalActive = createAction<boolean>(
  ACTIONS.SET_PROGRESS_MODAL_ACTIVE,
);

export const setOutOfTurnsModalActive = createAction<boolean>(
  ACTIONS.SET_OUT_OF_TURNS_MODAL_ACTIVE,
);

export const setStories = createAction<StoryType[]>(ACTIONS.SET_STORIES);
