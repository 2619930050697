/* eslint-disable no-console */
import { fetchData } from '@/services/fetchData';
import { setAvailableSubscriptions } from '@/store';
import { useDispatch } from 'react-redux';

export const useGetSubscriptions = () => {
  const dispatch = useDispatch();

  const getSubscriptions = async () => {
    try {
      const response = await fetchData({
        url: '/subscriptions',
      });

      if (response?.status === 200) {
        dispatch(setAvailableSubscriptions(response.data));
        return;
      }
    } catch {
      console.error('Error getting subscription plans');
      throw new Error('Error getting subscription plans');
    }
  };

  return { getSubscriptions };
};
