/* eslint-disable no-console */
import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import { BACKEND_URL } from '@/constants';
import { FETCH_METHODS } from '@/types';

export async function login({
  username,
  password,
}: {
  username: string;
  password: string;
}) {
  try {
    const fetchUrl = `${BACKEND_URL}/login`;

    const options: AxiosRequestConfig = {
      method: FETCH_METHODS.POST,
      headers: {
        'Content-Type': 'application/json',
      },
      data: {
        username,
        password,
      },
    };

    const response = await axios(fetchUrl, options);

    return response;
  } catch (error) {
    console.error('An error occurred while authorization');
    if (error instanceof AxiosError) {
      return error.response;
    }
  }
}
