import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/google/target.css?{\"path\":\"src/app/[locale]/layout.tsx\",\"import\":\"Archivo\",\"arguments\":[{\"subsets\":[\"latin\"]}],\"variableName\":\"inter\"}");
;
import(/* webpackMode: "eager", webpackExports: ["App"] */ "/app/src/app/[locale]/app.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/app/[locale]/layout.module.scss");
;
import(/* webpackMode: "eager", webpackExports: ["GoogleTagManager"] */ "/app/src/components/GoogleTagManager/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["YandexMetrika"] */ "/app/src/components/YandexMetrika/YandexMetrika.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["WithRedux"] */ "/app/src/providers/WithRedux.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/styles/globals.scss");
