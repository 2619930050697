import { WebApp } from '@twa-dev/types';
import { useEffect, useState } from 'react';

export const useTelegramWebApp = () => {
  const [loaded, setLoaded] = useState(false);
  const [data, setData] = useState<WebApp | null>(null);

  useEffect(() => {
    setLoaded(true);
  }, []);

  useEffect(() => {
    if (loaded) {
      if (window.Telegram) {
        const telegram = window.Telegram.WebApp;
        // telegram.disableVerticalSwipes();
        setData(telegram);
      }
    }
  }, [loaded]);

  return data;
};
