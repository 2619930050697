import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import { BACKEND_URL } from '@/constants';
import { FETCH_METHODS } from '@/types';

const axiosInstance = axios.create({
  baseURL: BACKEND_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

axiosInstance.interceptors.response.use(
  (response) => response,
  async (error: AxiosError) => {
    if (error.response?.status === 401) {
      console.error('Authorization failed. Retrying...');
      return await retryAuthRequest(error.config);
    }
    return Promise.reject(error);
  },
);

async function retryAuthRequest(config: AxiosRequestConfig | undefined) {
  if (!config) {
    throw new Error('No request config available for retry.');
  }
  try {
    return await axiosInstance.request(config);
  } catch (retryError) {
    console.error('Retry failed:', retryError);
    return Promise.reject(retryError);
  }
}

export async function auth(
  body: string | undefined = '',
  invite: string | null,
) {
  const inviteCode = invite ? `&invite_code=${invite}` : '';
  const fetchUrl = `/auth?data=${encodeURIComponent(body)}${inviteCode}`;

  try {
    const options: AxiosRequestConfig = {
      method: FETCH_METHODS.POST,
      url: fetchUrl,
    };

    const response = await axiosInstance.request(options);

    if (response?.data?.access_token) {
      localStorage.setItem('botai_access_token', response.data.access_token);
    }

    return response;
  } catch (error) {
    console.error('An error occurred during authorization:', error);
    if (error instanceof AxiosError) {
      return error.response;
    }
    return null;
  }
}
