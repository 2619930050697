/* eslint-disable no-console */
import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import { BACKEND_URL } from '@/constants';
import { FETCH_METHODS } from '@/types';
import { auth } from './auth';

type FetchParams = {
  url: string;
  method?: FETCH_METHODS;
  body?: Record<string, unknown>;
};

export async function fetchData({
  url,
  method = FETCH_METHODS.GET,
  body,
}: FetchParams) {
  const fetchUrl = `${BACKEND_URL}${url}`;
  const token = localStorage.getItem('botai_access_token');

  const options: AxiosRequestConfig = {
    method,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  };

  if (body) {
    options.data = body;
  }

  try {
    const response = await axios(fetchUrl, options);
    return response;
  } catch (error: unknown) {
    console.error('An error occurred while fetching data');

    if (error instanceof AxiosError) {
      if (error.response?.status === 401) {
        console.log('401 Unauthorized error. Attempting to re-authenticate...');
        await auth(window.Telegram.WebApp.initData, null);

        const newToken = localStorage.getItem('botai_access_token');
        options.headers = {
          ...options.headers,
          Authorization: `Bearer ${newToken}`,
        };

        try {
          const retryResponse = await axios(fetchUrl, options);
          return retryResponse;
        } catch (retryError: unknown) {
          if (retryError instanceof AxiosError) {
            console.error('Retry request failed', retryError);
            return retryError.response;
          }
          console.error('Unknown error during retry', retryError);
          throw retryError;
        }
      }
      return error.response;
    }

    console.error('Unknown error', error);
    throw error;
  }
}
