import { appReducer } from '.';
import { APP_REDUCER_NAME } from './constants';

type ReducerState = ReturnType<typeof appReducer>;

type State = {
  [APP_REDUCER_NAME]: ReducerState;
};

export const selectUser = (state: State) => state[APP_REDUCER_NAME].user;

export const selectOfferActive = (state: State) =>
  state[APP_REDUCER_NAME].offer.active;

export const selectSubscribeActiveModalActive = (state: State) =>
  state[APP_REDUCER_NAME].subscribeActiveModal;

export const selectOfferTimer = (state: State) =>
  state[APP_REDUCER_NAME].offer.timer;

export const selectHelpModalActive = (state: State) =>
  state[APP_REDUCER_NAME].helpModal;

export const selectSubscribeModalActive = (state: State) =>
  state[APP_REDUCER_NAME].subscribeModal;

export const selectTourState = (state: State) =>
  state[APP_REDUCER_NAME].tourState;

export const selectProgressModal = (state: State) =>
  state[APP_REDUCER_NAME].progressModal;

export const selectAppLoading = (state: State) =>
  state[APP_REDUCER_NAME].appLoading;

export const selectOfferDisclaimerModalActive = (state: State) =>
  state[APP_REDUCER_NAME].offer.disclaimer;

export const selectOfferUpgradeModalActive = (state: State) =>
  state[APP_REDUCER_NAME].offer.upgrade;

export const selectOutOfTurnsModalActive = (state: State) =>
  state[APP_REDUCER_NAME].outOfTurnsModal;

export const selectStories = (state: State) => state[APP_REDUCER_NAME].stories;

export const selectMysterySolvedModalActive = (state: State) =>
  state[APP_REDUCER_NAME].mysterySolvedModal;

export const selectSubscribeSuccessModalActive = (state: State) =>
  state[APP_REDUCER_NAME].subscribeSuccessModal;

export const selectSubscribeUpgradeModalActive = (state: State) =>
  state[APP_REDUCER_NAME].subscribeUpgradeModal;

export const selectKeyboardActive = (state: State) =>
  state[APP_REDUCER_NAME].keyboardActive;

export const selectHowToPlayModalActive = (state: State) =>
  state[APP_REDUCER_NAME].howToPlayModal;

export const selectAvailableSubscriptions = (state: State) =>
  state[APP_REDUCER_NAME].availableSubscriptions;

export const selectUserTasks = (state: State) =>
  state[APP_REDUCER_NAME].userTasks;

export const selectUserParams = (state: State) =>
  state[APP_REDUCER_NAME].userParams;

export const selectClosedBetaModalActive = (state: State) =>
  state[APP_REDUCER_NAME].closedBetaModal;
