import { createReducer } from '@reduxjs/toolkit';

import {
  setUser,
  setOfferTimer,
  setOfferActive,
  setHelpModalActive,
  setTourState,
  setSubscribeModalActive,
  setProgressModalActive,
  setOfferDisclaimerModalActive,
  setOfferUpgradeModalActive,
  setStories,
  setOutOfTurnsModalActive,
  setMysterySolvedModalActive,
  setSubscribeSuccessModalActive,
  setSubscribeUpgradeModalActive,
  setKeyboardActive,
  setHowToPlayModalActive,
  setSubscribeActiveModalActive,
  setAvailableSubscriptions,
  setUserTasks,
  setUserParams,
  setClosedBetaModalActive,
} from './actions';
import { InitialState } from './types';

const initialState: InitialState = {
  user: null,
  offer: {
    timer: 300,
    active: false,
    disclaimer: false,
    upgrade: false,
  },
  helpModal: false,
  subscribeModal: false,
  subscribeActiveModal: false,
  subscribeSuccessModal: false,
  subscribeUpgradeModal: false,
  tourState: {
    active: false,
    repeat: false,
  },
  progressModal: false,
  outOfTurnsModal: false,
  mysterySolvedModal: false,
  appLoading: false,
  stories: [],
  keyboardActive: false,
  howToPlayModal: false,
  availableSubscriptions: [],
  userTasks: [],
  userParams: null,
  closedBetaModal: false,
};

export const appReducer = createReducer(initialState, (builder) => {
  builder.addCase(setUser, (state, action) => {
    state.user = action.payload;
  });
  builder.addCase(setOfferActive, (state, action) => {
    state.offer.active = action.payload;
  });
  builder.addCase(setOfferTimer, (state, action) => {
    state.offer.timer = action.payload;
  });
  builder.addCase(setHelpModalActive, (state, action) => {
    state.helpModal = action.payload;
  });
  builder.addCase(setSubscribeModalActive, (state, action) => {
    state.subscribeModal = action.payload;
  });
  builder.addCase(setTourState, (state, action) => {
    state.tourState = action.payload;
  });
  builder.addCase(setProgressModalActive, (state, action) => {
    state.progressModal = action.payload;
  });
  builder.addCase(setOfferDisclaimerModalActive, (state, action) => {
    state.offer.disclaimer = action.payload;
  });
  builder.addCase(setOfferUpgradeModalActive, (state, action) => {
    state.offer.upgrade = action.payload;
  });
  builder.addCase(setStories, (state, action) => {
    state.stories = action.payload;
  });
  builder.addCase(setOutOfTurnsModalActive, (state, action) => {
    state.outOfTurnsModal = action.payload;
  });
  builder.addCase(setMysterySolvedModalActive, (state, action) => {
    state.mysterySolvedModal = action.payload;
  });
  builder.addCase(setSubscribeSuccessModalActive, (state, action) => {
    state.subscribeSuccessModal = action.payload;
  });
  builder.addCase(setSubscribeActiveModalActive, (state, action) => {
    state.subscribeActiveModal = action.payload;
  });
  builder.addCase(setSubscribeUpgradeModalActive, (state, action) => {
    state.subscribeUpgradeModal = action.payload;
  });
  builder.addCase(setKeyboardActive, (state, action) => {
    state.keyboardActive = action.payload;
  });
  builder.addCase(setHowToPlayModalActive, (state, action) => {
    state.howToPlayModal = action.payload;
  });
  builder.addCase(setAvailableSubscriptions, (state, action) => {
    state.availableSubscriptions = action.payload;
  });
  builder.addCase(setUserTasks, (state, action) => {
    state.userTasks = action.payload;
  });
  builder.addCase(setUserParams, (state, action) => {
    state.userParams = action.payload;
  });
  builder.addCase(setClosedBetaModalActive, (state, action) => {
    state.closedBetaModal = action.payload;
  });
});
