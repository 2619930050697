import styles from './Loader.module.scss';
import cn from 'classnames';

type LoaderProps = {
  fullScreen?: boolean;
  dots?: boolean;
};

export function Loader({ fullScreen = false, dots = false }: LoaderProps) {
  const wrapperClassName = cn(styles.wrapper, {
    [styles.fullScreen]: fullScreen,
  });

  if (dots) {
    return (
      <div className={styles.wrapper}>
        <div className={styles.dots}></div>
      </div>
    );
  }

  return (
    <div className={wrapperClassName}>
      <img src="/svg/loader.svg" alt="loader" />
    </div>
  );

  // return (
  //   <div className={wrapperClassName}>
  //     <div className={styles.loader}>
  //       <div></div>
  //       <div></div>
  //       <div></div>
  //       <div></div>
  //     </div>
  //   </div>
  // );
}
