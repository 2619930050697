import { OFFER_TIMER } from '@/constants';
import { setOfferActive, setOfferTimer } from '@/store';
import { useDispatch } from 'react-redux';

export const useCloseOffer = () => {
  const dispatch = useDispatch();

  const close = () => {
    dispatch(setOfferActive(false));
    dispatch(setOfferTimer(0));
    localStorage.removeItem(OFFER_TIMER);
  };

  return { close };
};
