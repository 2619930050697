'use client';
import { useEffect } from 'react';
import { useRouter, useSearchParams } from 'next/navigation';
import { Loader } from '@/components/Loader/Loader';

function Home() {
  const router = useRouter();
  const params = useSearchParams();
  const referral = params.get('tgWebAppStartParam');

  useEffect(() => {
    router.replace(
      `/storylines?${referral ? `tgWebAppStartParam=${referral}` : ''}`,
    );
  }, [router, referral]);

  return <Loader fullScreen />;
}

export default Home;
