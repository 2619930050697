export const BACKEND_URL =
  'https://ai-chat-game-262065803323.us-central1.run.app';

export const BOT_API_URL =
  'https://legends-tma-bot-launcher-backend-264487711799.us-central1.run.app';

export const REFERRAL_TEXT = `🎮 Join today and get +5 turns and 200 coins as a gift to start your journey strong! 💥\n \n 🔥 Your Choices, Your Legend — Enter 8 Legends Now! 🔥\n \n 🚀 Unlock epic adventures where every decision shapes the story. Dive into captivating narratives filled with mystery, battles, and unexpected twists! \n \n ⚔️✨ You create the story — every choice crafts a new chapter in your legend.\n \n ⚡️ Don’t wait — your story is waiting to be written! The world of 8 Legends is full of secrets to uncover and powerful rivals to conquer. Are you ready to rise above? 🌍🔮`;

export const SPACE = process.env.SPACE;

export const MOBILE_RESOLUTION = 435;
export const TABLET_RESOLUTION = 768;

export const ONBOARDING_PASSED = 'ONBOARDING_PASSED';

export const SUPPORT_URL = 'https://t.me/help_8legends';

export const OFFER_TIMER = 'OFFER_TIMER';

export const TON_SUBSCRIPTION_PRICE = '10000'; // in nanotons

export const TON_PAYMENT_ADDRESS =
  'UQAVUmikBPbn7RoAbmIcMK4UuOBqBYgOZnfzFsbcttzNUEO5';

export const STARS_SUBSCRIPTION_PRICE = '';

export const TELEGRAM_CHAT_URL = 'https://t.me/ArtLegends8Chat';
export const TELEGRAM_CHAT_RU_URL = 'https://t.me/ArtLegends8_ru_chat';
